import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getMemberById, getSelfMember } from '../../services/members';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const wixCodeApi = controllerConfig.wixCodeApi;
  const loggedInMember = await getSelfMember(flowAPI)();
  // console.log(
  //   await getMemberById(flowAPI)('5e8c26eb-5f3b-46ee-8eac-eabef714035e'),
  // );
  const path = wixCodeApi.location.path;
  const productId = path[1];
  const navigateToEdit = () => {
    const url = wixCodeApi.location.baseUrl + '/productform/' + productId;
    wixCodeApi?.location?.to!(url);
  };
  const navigateToFeed = () => {
    let url = wixCodeApi.location.baseUrl + '/feedpage';

    // Hack for demo
    if (wixCodeApi.location.baseUrl.indexOf('wix-bazaar') > -1) {
      url = wixCodeApi.location.baseUrl + '/all-items';
    }
    wixCodeApi.location?.to!(url);
  };
  return {
    async pageReady() {
      controllerConfig.setProps({
        productId,
        loggedInMember,
        instance: flowAPI.controllerConfig.appParams.instance,
        navigateToEdit,
        navigateToFeed,
      });
      // Here you can pass props to the Widget
    },
  };
};

export default createController;
